import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import IntroCopy from "../../components/IntroCopy";
import FullWidthInfo from "../../components/FullWidthInfo";
import ImageGallery from "../../components/ImageGallery";
import ImageLockup from "../../components/ImageLockup";

const FlatRoofingPage = ({ data }) => (
  <Layout>
    <main>
      <SEO title="Portfolio - Flat Roofing" />
      <IntroCopy
        headline="Flat Roofing"
        body="We offer a variety of flat roofing options including edpm rubber, 3 layer felt laid in hot bitument and lead. Agace Bros Roofing comply with all flat roofing application systems and we deliver quality finished surfaces that last for years and withstand the testing demands of the temperamental UK climate."
        topPadding
      />
      <ImageGallery
        images={data.images.nodes.filter(image => image.name === "1")}
      />
      <FullWidthInfo
        headline="Duoply EDPM Rubber Roofing"
        body="Rubber Roofing is a relatively modern flat roofing technique. Duoply Fleece Reinforced EDPM Rubber roofing carries a 20 year guarantee and in their studies has provided to last in excess of 50 years. Due to this it is becoming increasingly popular and has the advantage of its flexible nature which allows for settlement in roofing timbers."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "2")}
        right={data.images.nodes.filter(image => image.name === "3")}
      />
      <FullWidthInfo
        headline="3 Layer Cross Bonded Flat Roofing"
        body="3 Layer flat roofing has been the most popular flat roofing option for many years. We guarantee Flat roofs for 10 years and expect them to last anything from 12-15 years. Felt roofing has the benefits of lower cost and of being able to be installed on an existing deck providing there is no evidence of rot."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "4")}
        right={data.images.nodes.filter(image => image.name === "5")}
      />
    </main>
  </Layout>
);

export const query = graphql`
  query FlatRoofingImages {
    images: allFile(
      filter: { relativeDirectory: { eq: "portfolio/flat-roofing" } }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default FlatRoofingPage;
